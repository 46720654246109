<section class="drawer">
    <div class="col-md-12 size-img back-img-players">
            <div class="effect-cover">
                <h3 class="txt-advert animated fadeInUp">Člani teniške sekcije</h3>
                <p class="txt-advert-sub animated">različnih kategorije igralcev</p>
            </div>
      </div>

      <section id="players" class="container secondary-page">
        <div class="general general-results players">
                
             <div class="top-score-title right-score col-md-12">
                  <h3>Člani <span></span><span class="point-little">.</span></h3>
                  <p class="txt-right">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
                     quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                  <p class="txt-right txt-dd-second">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                  <div class="container">
                    <div class="row mt-3">
                        <div class="col-md-2 atp-player">
                            <a href="single_player.html"><img  class="img-fluid" src="./../../assets/images/face.jpeg" alt="">
                            <p>IGOR ŠMAUC</p></a>
                          </div>
                          <div class="col-md-2 atp-player">
                            <a href="single_player.html"><img src="./../../assets/images/face.jpeg" alt="">
                             <p>ANDREJ HORVAT</p></a>
                          </div>
                          <div class="col-md-2 atp-player">
                            <a href="single_player.html"><img src="./../../assets/images/face.jpeg" alt="">
                            <p>DANIJEL KARLOVČEC</p></a>
                          </div>
                          <div class="col-md-2 atp-player">
                            <a href="single_player.html"><img src="./../../assets/images/face.jpeg" alt="">
                            <p>BORUT BABIČ</p></a>
                          </div>
                          
                          <div class="col-md-2 atp-player">
                            <a href="single_player.html"><img src="./../../assets/images/face.jpeg" alt="">
                            <p>Milos Rotin</p></a>
                          </div>
                          <div class="col-md-2 atp-player">
                            <a href="single_player.html"><img src="./../../assets/images/face.jpeg" alt="">
                            <p>Gregor Goe </p></a>
                          </div>
                          <div class="col-md-2 atp-player">
                            <a href="single_player.html"><img src="./../../assets/images/face.jpeg" alt="">
                            <p>Tomas Berin</p></a>
                          </div>
                          <div class="col-md-2 atp-player">
                            <a href="single_player.html"><img src="./../../assets/images/face.jpeg" alt="">
                            <p>Andy Morra</p></a>
                          </div>
                          <div class="col-md-3 atp-player">
                            <a href="single_player.html"><img src="./../../assets/images/face.jpeg" alt="">
                            <p>Jo-Wilf T. </p></a>
                          </div>
                      </div>
                  </div>
                  
                  
                  <div class="content-wtp-player">
                      <h3>Mlajši <span>člani</span><span class="point-little">.</span></h3>
                      <p class="txt-right">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
                     quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                  <p class="txt-right txt-dd-second">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                  <div class="container">
                    <div class="row mt-3">
                        <div class="col-md-2 atp-player">
                            <a href="single_player.html"><img  class="img-fluid" src="./../../assets/images/face.jpeg" alt="">
                            <p>IGOR ŠMAUC</p></a>
                          </div>
                          <div class="col-md-2 atp-player">
                            <a href="single_player.html"><img src="./../../assets/images/face.jpeg" alt="">
                             <p>ANDREJ HORVAT</p></a>
                          </div>
                          <div class="col-md-2 atp-player">
                            <a href="single_player.html"><img src="./../../assets/images/face.jpeg" alt="">
                            <p>DANIJEL KARLOVČEC</p></a>
                          </div>
                          <div class="col-md-2 atp-player">
                            <a href="single_player.html"><img src="./../../assets/images/face.jpeg" alt="">
                            <p>BORUT BABIČ</p></a>
                          </div>
                          
                          <div class="col-md-2 atp-player">
                            <a href="single_player.html"><img src="./../../assets/images/face.jpeg" alt="">
                            <p>Milos Rotin</p></a>
                          </div>
                          <div class="col-md-2 atp-player">
                            <a href="single_player.html"><img src="./../../assets/images/face.jpeg" alt="">
                            <p>Gregor Goe </p></a>
                          </div>
                          <div class="col-md-2 atp-player">
                            <a href="single_player.html"><img src="./../../assets/images/face.jpeg" alt="">
                            <p>Tomas Berin</p></a>
                          </div>
                          <div class="col-md-2 atp-player">
                            <a href="single_player.html"><img src="./../../assets/images/face.jpeg" alt="">
                            <p>Andy Morra</p></a>
                          </div>
                          <div class="col-md-3 atp-player">
                            <a href="single_player.html"><img src="./../../assets/images/face.jpeg" alt="">
                            <p>Jo-Wilf T. </p></a>
                          </div>
                      </div>
                  </div>
              </div>
             </div><!--Close Top Match-->
             
          </div></section>

</section>