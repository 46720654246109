<section class="drawer">
    <div class="col-md-12 size-img back-img-news">
            <div class="effect-cover">
                <h3 class="txt-advert animated fadeInUp">Novice</h3>
                <p class="txt-advert-sub animated">Vse ljutomerske teniške informacije na enem mestu</p>
            </div>
      </div>

    <section id="news" class="container secondary-page">
    <div class="general general-results players">
    <div class="top-score-title right-score col-md-12">
            <h3><span>ARHIVS</span> NOVIC<span class="point-little">.</span></h3>
            <div class="col-md-12 news-page d-flex">
            <img class="img-djoko" src="http://placehold.it/270x180" alt="">
            
            <div class="data-news-pg">
                <p class="news-dd">APRIL 3, 2021 12:00 PM</p>
                <h3>NOVA SPLETNA STRAN ZA REZERVACIJO TERMINOV </h3>
                <p>Po več kot letu dni smo pripravili novi spletnoi stran za rezervacijo terminov na teniških igriščih v Ljutomeru. Na spletnih straneh bodo objavljene tudi novice in ostale zanimivosti za člane in vse ostale ljubitelje tenisa.</p>
                <div class="news-more"><a href="single_news.html" class="ca-more"><i class="fa fa-angle-double-right"></i>več...</a></div>
            </div>
            </div>

            <div class="col-md-12 news-page d-flex">
                <img class="img-djoko" src="http://placehold.it/270x180" alt="">
                
                <div class="data-news-pg">
                    <p class="news-dd">APRIL 15, 2021 12:00 PM</p>
                    <h3>ZAČETEK SEZONE</h3>
                    <p>Po obilo težavah in izboljšanju epidemiološke situacije v Sloveniji, se z dnem 15.4.2021 odpirajo vsa 3 teniška igrišča v Ljutomeru. Vsem igralcem želimo obilo teniških užitkov. </p>
                    <div class="news-more"><a href="single_news.html" class="ca-more"><i class="fa fa-angle-double-right"></i>več...</a></div>
                </div>
                </div>
            
        
    </div><!--Close Top Match-->
    </div>
    </section>
</section>


<!-- <app-footer></app-footer> -->