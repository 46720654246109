<section id="contact" class="secondary-page">
    <div class="general">
        <div id="map_container">
            <div id="map_canvas">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5490.687319115062!2d16.18869267218848!3d46.52110104133165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476f45c2d04e2b83%3A0x28f53f96609ee1a!2s%C5%A0portna%20zveza%20Ljutomer!5e0!3m2!1sen!2shr!4v1617626968102!5m2!1sen!2shr"
                    width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="content-link col-md-12">
            <div id="info-company" class="top-score-title align-center">

                <h3>Info</h3>
                <p class="mb-4">V primeru dodatnih informacij se prosim obrnite na kontakne osebe preko spodnjih
                    kontaktov.</p>
                <div>
                    <p><i class="fa fa-phone"></i>041 123 456 </p>
                    <p><i class="fa fa-envelope-o"></i>info@tenis-ljutomer.si </p>
                    <p><i class="fa fa-map-marker"></i>Naslov, 9240 Ljutomer</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="client-sport client-sport-nomargin col-md-12">
            <div class="content-banner">
                <h3>Sponzorji</h3>
                <ul class="sponsor second">
                    <li><img src="http://placehold.it/273x133" alt="" /></li>
                    <li><img src="http://placehold.it/273x133" alt="" /></li>
                    <li><img src="http://placehold.it/273x133" alt="" /></li>
                    <li><img src="http://placehold.it/273x133" alt="" /></li>
                    <li><img src="http://placehold.it/273x133" alt="" /></li>
                    <li><img src="http://placehold.it/273x133" alt="" /></li>
                </ul>
            </div>
        </div>
    </div>
 
        

    
</section>

<app-footer></app-footer>
