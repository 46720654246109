<section class="drawer">
    <div class="col-md-12 size-img back-img">
     <div class="effect-cover">
            <h3 class="txt-advert animated fadeInUp">ATP World Tour Fan Credential</h3>
            <p class="txt-advert-sub animated">Your all-access pass to experience the action on tour</p>
         </div>
     </div>

<section id="login" class="container secondary-page">  
<div class="general general-results players d-flex">
   <div class="top-score-title right-score col-md-6">
        <h3>Dobrodošli<span class="point-int"> !</span></h3>
        <div class="col-md-12 login-page login-w-page">
           <p class="logiin-w-title">Z vpisom boste lahko rezervirali teren, pregledali informacije o ostalih članih, spremeljeli rezultate lige, ipd. 
           </p>
           <h3><img class="ball-tennis" src="images/ball.png" alt="">ATP World Tour Insider</h3>
           <p>Get off-court and go behind the scenes of the tour each week.</p>
           <h3><img class="ball-tennis" src="images/ball.png" alt="">ATP World Tour Weekly</h3>
           <p>Official report and schedule including results</p>
        </div>
   </div><!--Close welcome-->
   <!-- LOGIN BOX -->
   <div class="top-score-title right-score col-md-6">
       <h3>Vpiši<span> se</span><span class="point-int"> !</span></h3>
        <div class="col-md-12 login-page">
          <form method="post" class="login-form">            
                <div class="name">
                    <label for="name_login">Email:</label><div class="clear"></div>
                    <input id="name_login" name="name_login" type="text" placeholder="example@domain.com" required="">
                </div>
                <div class="pwd">
                    <label for="password_login">Password:</label><div class="clear"></div>
                    <input id="password_login" name="password_login" type="password" placeholder="********" required="">
                </div>
                <div id="login-submit">
                    <input type="submit" value="Submit">
                </div>
             </form>
        </div>
        
   </div><!--Close Login-->

  </div> 
</section>


</section>