<!--SECTION TOP LOGIN-->
<section class="content-top-login">
  <div class="container">
    <div class="col-md-12">
      <div class="box-support">
        <p class="support-info"><i class="fa fa-envelope-o"></i> info@tenis-ljutomer.si</p>
      </div>
      <div class="box-login">

        <a [routerLink]="['/login']">Vpis</a>

      </div>

    </div>
  </div>
</section>

<section class="container box-logo">
  <header>
    <div class="content-logo col-md-12">
      <div class="logo">
        <img src="./../../../assets/images/logo2.png" alt="">
      </div>

      <div class="bt-menu"><a class="menu" (click)="[this.openMenu = !this.openMenu]"><span>≡</span> Menu</a></div>

      <div class="box-menu" [class.active]="openMenu">

        <nav id="cbp-hrmenu" class="cbp-hrmenu">
          <ul id="menu">
            <li><a class="lnk-menu" [routerLink]="['/home']" [routerLinkActive]="['active']" (click)="[this.openMenu = !this.openMenu]">Domov</a></li>

            <li><a class="lnk-menu" [routerLink]="['/members']" [routerLinkActive]="['active']"(click)="[this.openMenu = !this.openMenu]">Igralci</a></li>
            <li><a class="lnk-menu" [routerLink]="['/league']" [routerLinkActive]="['active']" (click)="[this.openMenu = !this.openMenu]">Liga</a></li>
            <li><a class="lnk-menu" [routerLink]="['/news']" [routerLinkActive]="['active']" (click)="[this.openMenu = !this.openMenu]">Novice</a></li>
            <li><a class="lnk-menu" [routerLink]="['/booking']" [routerLinkActive]="['active']" (click)="[this.openMenu = !this.openMenu]">Rezervacija</a></li>
            <li><a class="lnk-menu" [routerLink]="['/contact']" [routerLinkActive]="['active']" (click)="[this.openMenu = !this.openMenu]">Kontakt</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</section>