<section class="drawer">
    <div class="col-md-12 size-img back-img-match">
        <div class="effect-cover">
            <h3 class="txt-advert animated fadeInUp">TENIŠKA LIGA 2021</h3>
        </div>
   </div>

<section id="allmatch" class="container secondary-page">
<div class="general general-results">
   <div class="top-score-title right-score col-md-12">
        <h3 class="mt-5">ODIGRANI <span>DVOBOJI</span><span class="point-little">.</span></h3>
        <div class="main mb-5">
                
                <div class="tabs animated-slide-2 matches-tbs">
                    
                    <div class="tab-content">
                        <div id="tab1111" class="tab active">
                           <table class="match-tbs">
                                 <tbody><tr><td class="match-tbs-title" colspan="7">1. igrišče - 25.4.2021</td></tr>
                                 <tr class="match-sets"><td></td><td class="fpt">Pts</td><td class="fpt">1</td><td class="fpt">2</td><td class="fpt">3</td><td class="fpt">4</td><td>5</td></tr>
                                <tr><td class="fpt">N. Robert (SRB) <i class="fa fa-check"></i></td><td class="fpt">3</td><td class="fpt">6</td><td class="fpt">6</td><td class="fpt">6</td><td class="fpt">4</td><td>6</td></tr>
                                <tr><td class="fpt fpt2">D. Giorg (SWZ)</td><td class="fpt fpt2">6</td><td class="fpt fpt2">4</td><td class="fpt fpt2">2</td><td class="fpt fpt2">5</td><td class="fpt fpt2">6</td><td class="fpt2">4</td></tr>
                            </tbody></table>
                            <table class="match-tbs">
                                 <tbody><tr><td class="match-tbs-title" colspan="7">2. igrišče - 27.4.2021</td></tr>
                                 <tr class="match-sets"><td class=""></td><td class="fpt">Pts</td><td class="fpt">1</td><td class="fpt">2</td><td class="fpt">3</td><td class="fpt">4</td><td>5</td></tr>
                                <tr><td class="fpt">J.Doe (USA) / J.Siriu (USA)</td><td class="fpt">3</td><td class="fpt">2</td><td class="fpt">6</td><td class="fpt">6</td><td class="fpt">4</td><td>6</td></tr>
                                <tr><td class="fpt fpt2">J.Courie (USA) / M.Blande (SWE) <i class="fa fa-check"></i></td><td class="fpt fpt2">4</td><td class="fpt fpt2">4</td><td class="fpt fpt2">2</td><td class="fpt fpt2">5</td><td class="fpt fpt2">6</td><td class="fpt2">4</td></tr>
                            </tbody></table>
                            <table class="match-tbs">
                                 <tbody><tr><td class="match-tbs-title" colspan="7">1. igrišče - 29.4.2021</td></tr>
                                 <tr class="match-sets"><td class=""></td><td class="fpt">Pts</td><td class="fpt">1</td><td class="fpt">2</td><td class="fpt">3</td><td class="fpt">4</td><td>5</td></tr>
                                <tr><td class="fpt">T.Susan (CZE) <i class="fa fa-check"></i></td><td class="fpt">3</td><td class="fpt">6</td><td class="fpt">6</td><td class="fpt">6</td><td class="fpt">4</td><td>6</td></tr>
                                <tr><td class="fpt fpt2">J.Paul (USA)</td><td class="fpt fpt2">6</td><td class="fpt fpt2">4</td><td class="fpt fpt2">2</td><td class="fpt fpt2">5</td><td class="fpt fpt2">6</td><td class="fpt2">4</td></tr>
                            </tbody></table>
                            
                           
                        </div>
                    </div>
                    
                </div>
            </div>
   </div><!--Close Top Match-->
  
   </div></section>



</section>