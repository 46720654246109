<section id="summary-slider">
    <div class="general">
    <div class="content-result content-result-news col-md-12">
      <div id="textslide" class="effect-backcolor">
         <div class="container">
             <div class="col-md-12 slide-txt">
                 <p class="sub-result aft-little welcome linetheme-left" style="transform: translate(0px, 0px); opacity: 1;">Tenis</p>
                 <p class="sub-result aft-little linetheme-right" style="transform: translate(0px, 0px); opacity: 1;">V Ljutomeru<span class="point-big">.</span></p>
             </div>
         </div>
      </div>
    </div>
    <div id="slidematch" class="col-xs-12 col-md-12">
           <div class="content-match-team-wrapper">
              <span class="gdlr-left">Otvoritev sezone</span>
              <span class="gdlr-upcoming-match-versus">PREDVIDOMA</span>
              <span class="gdlr-right">KONEC APRILA</span>
           </div>
          
        </div>
  </div>
</section>
