
<div class="container">
    <div class="row mt-5">
        <div class="col-md-4 text-left">
          <div class="btn-group">
            <div
              class="btn btn-primary"
              mwlCalendarPreviousView
              [view]="view"
              [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()"
            >
              Previous
            </div>
            <div
              class="btn btn-outline-secondary"
              mwlCalendarToday
              [(viewDate)]="viewDate"
            >
              Today
            </div>
            <div
              class="btn btn-primary"
              mwlCalendarNextView
              [view]="view"
              [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()"
            >
              Next
            </div>
          </div>
        </div>
        <div class="col-md-4 text-center">
          <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
        </div>
        <div class="col-md-4 text-right">
          <div class="btn-group">
            
            <div
              class="btn btn-primary"
              (click)="setView(CalendarView.Week)"
              [class.active]="view === CalendarView.Week"
            >
              Week
            </div>
            <div
              class="btn btn-primary"
              (click)="setView(CalendarView.Day)"
              [class.active]="view === CalendarView.Day"
            >
              Day
            </div>
          </div>
        </div>
      </div>
      <br />
      <div [ngSwitch]="view">
        
        <mwl-calendar-week-view
          *ngSwitchCase="CalendarView.Week"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refresh"
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          *ngSwitchCase="CalendarView.Day"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refresh"
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-day-view>
      </div>
      
      <!-- Everything you see below is just for the demo, you don't need to include it in your app -->
      
      <br /><br /><br />
      
     
      
   
      
      <ng-template #modalContent let-close="close">
        <div class="modal-header">
          <h5 class="modal-title">Event action occurred</h5>
          <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            Action:
            <pre>{{ modalData?.action }}</pre>
          </div>
          <div>
            Event:
            <pre>{{ modalData?.event | json }}</pre>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" (click)="close()">
            OK
          </button>
        </div>
      </ng-template>
      
</div>